<template>
    <div>
        <b-row class="d-flex flex-wrap px-1">
            <div
                v-for="item in data"
                :class="`col card round p-1 ${item.customClass}`"
            >
                <div class="row">
                    <div class="col-auto">
                        <b-avatar size="60" :variant="item.color">
                            <feather-icon size="30" :icon="item.icon" />
                        </b-avatar>
                    </div>
                    <div class="col my-auto text-center">
                        <h2 class="font-weight-bolder mb-0">
                            {{ item.title }}
                        </h2>
                        <span class="font-small-3 mb-0">
                            {{ item.subtitle }}
                        </span>
                    </div>
                </div>
            </div>
        </b-row>
        <b-row>
            <b-col>
                <!-- <h3>Asistencias</h3> -->
                <b-card no-body>
                    <tabla-general
                        :columns="columnsTable"
                        :items="itemsTable"
                        button-activated
                    >
                        <template v-slot:boton>
                            <b-button
                                variant="primary"
                                @click="abrirSidebarCrearAsistencia"
                            >
                                Crear
                            </b-button>
                        </template>

                        <template #cell(nombre_asistido)="data">
                            <div
                                @click="
                                    $router.push({
                                        name: 'ver-asistencia',
                                        params: { id: data.item.id },
                                    })
                                "
                            >
                                {{ data.item.nombre_asistido }}
                            </div>
                        </template>

                        <template #cell(estado)="data">
                            <b-badge
                                v-show="data.item.estado == 0"
                                variant="light-warning"
                                >Pendiente</b-badge
                            >
                            <b-badge
                                v-show="data.item.estado == 1"
                                variant="light-success"
                                >Realizada</b-badge
                            >
                            <b-badge
                                v-show="data.item.estado == 2"
                                variant="light-danger"
                                >Cancelada</b-badge
                            >
                            <b-badge
                                v-show="data.item.estado == 3"
                                variant="light-info"
                                >Reagendada</b-badge
                            >
                        </template>
                    </tabla-general>
                </b-card>
            </b-col>
        </b-row>

        <!-- sidebar -->
        <sidebar-crear-asistencia
            ref="sidebarCrearAsistencia"
        ></sidebar-crear-asistencia>
    </div>
</template>
<script>
import { ref, onMounted } from "@vue/composition-api";
export default {
    components: {
        SidebarCrearAsistencia: () =>
            import("../sidebars/SidebarCrearAsistencia.vue"),
    },
    setup(props, context) {
        const data = ref([
            {
                icon: "CheckIcon",
                color: "light-success",
                title: "200",
                subtitle: "Asistencias hechas",
                customClass:
                    "text-center mr-1 animate__animated animate__backInLeft",
            },
            {
                icon: "AlertCircleIcon",
                color: "light-warning",
                title: "12",
                subtitle: "Asistencias pendientes",
                customClass:
                    "text-center mr-1 animate__animated animate__backInLeft",
            },
            {
                icon: "XIcon",
                color: "light-danger",
                title: "20",
                subtitle: "Asistencias canceladas",
                customClass:
                    "text-center mr-1 animate__animated animate__backInRight",
            },
            {
                icon: "RotateCcwIcon",
                color: "light-info",
                title: "8",
                subtitle: "Asistencias reagendadas",
                customClass:
                    "text-center animate__animated animate__backInRight",
            },
        ]);

        const columnsTable = ref([
            { key: "id", sortable: true, label: "#" },
            {
                key: "nombre_asistido",
                sortable: true,
                label: "Nombre paciente",
            },
            { key: "fecha", sortable: true },
            { key: "estado", sortable: true, class: "text-center" },
            { key: "soliticitado", sortable: true, label: "Solicitado" },
            { key: "creado_por", sortable: true, label: "Creado por" },
        ]);

        const itemsTable = ref([
            {
                id: 124,
                fecha: "03 jul. 2022",
                estado: 0,
                nombre_asistido: "Glorieta Navas",
                solicitado: "Med. Oscar",
                creado_por: "Luz Monsale",
            },
            {
                id: 124,
                fecha: "03 jul. 2022",
                estado: 1,
                nombre_asistido: "Glorieta Navas",
                solicitado: "Med. Oscar",
                creado_por: "Luz Monsale",
            },
            {
                id: 124,
                fecha: "03 jul. 2022",
                estado: 2,
                nombre_asistido: "Glorieta Navas",
                solicitado: "Med. Oscar",
                creado_por: "Luz Monsale",
            },
            {
                id: 124,
                fecha: "03 jul. 2022",
                estado: 3,
                nombre_asistido: "Glorieta Navas",
                solicitado: "Med. Oscar",
                creado_por: "Luz Monsale",
            },
        ]);

        function abrirSidebarCrearAsistencia() {
            context.refs.sidebarCrearAsistencia.toggle();
        }

        return { data, columnsTable, itemsTable, abrirSidebarCrearAsistencia };
    },
};
</script>
